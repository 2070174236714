<script lang="ts" setup>
import { map } from 'rxjs/operators'
import { showDialog } from 'vant'
import { useDayjs } from '#dayjs'
import { TaskApi } from '~/net/apis/task_api'

const props = withDefaults(
  defineProps<{
    modelValue: boolean
  }>(),
  {
    modelValue: false
  }
)
const emits = defineEmits<{
  'update:modelValue': [value: boolean]
  close: [value: boolean]
}>()
const dayjs = useDayjs()

const minDate = dayjs().toDate()
const today = dayjs().date()
const close = () => emits('update:modelValue', false)

const Rule = useObservable(from(TaskApi.getRuleLoad()).pipe(map(({ data }) => data)))
const list = useObservable(from(TaskApi.queryDaySignTaskList({})).pipe(map(({ data }) => data)))
const confirmButtonDisabled = computed<boolean>(() => {
  return list.value ? (list.value[today - 1].status === 1) : false
})
function formatter(day: Day) {
  const _day = Number(day.text)
  const info = list.value ? list.value[_day - 1] : {}
  const status = _day > today ? 'nextTime' : _day == today ? 'todayTime' : 'lastTime'
  return {
    ...day,
    topInfo: `${info.status === 1 ? '.' : ''}`,
    bottomInfo: `${info.rewardInfo}`,
    className: status
  }
}

async function confirmHandle(value: Day) {
  if (confirmButtonDisabled.value)
    return
  const id = dayjs(value).format('YYYYMMDD')
  const { data } = await TaskApi.userSign({ id })

  showDialog({
    className: 'myDialog',
    title: '提示',
    message: data,
    theme: 'round-button',
    confirmButtonText: '关闭提示'
  }).then(() => {
    close()
  })
}
</script>

<template>
  <van-overlay :show="modelValue" style="z-index: 2000">
      <div h-screen flex items-center justify-center>
        <div pos-relative flex flex-col items-center justify-center px-15px class="warrper"
        :class="{ disabled: confirmButtonDisabled }"
        @click.stop>
          <BaseIcon name="close" size="2.5" pos-absolute right-2 top-2 p-2 @click="close" />
          <div>
            <van-calendar
              :poppable="false"
              :show-mark="false"
              :show-confirm="true"
              :max-date="minDate"
              :formatter="formatter"
              color="#FD5B03"
              :row-height="29"
              :style="{ height: '320px' }"
              :readonly="true"
              @confirm="confirmHandle"
          >
        <template #title>
          <p>
            天天签到
          </p>
        </template>
        <template #confirm-text="confirmButtonDisabled">
          {{ confirmButtonDisabled ? '已签到' : '签到' }}
        </template>
        </van-calendar>
          </div>
          <div class="rule" v-html="Rule" />
        </div>
      </div>
  </van-overlay>
</template>

<style scoped>
:deep(.van-loading--vertical) {
  justify-content: center;
}
.warrper{
  margin: 0 15px;
  background: #111425;
}
:deep(.van-calendar__day){
  font-size: 12px;
}
:deep(.van-calendar){
  background: #111425;
}
:deep(.van-calendar__header-title){
  color: #E8e8e8;
  background: #111425;
}
:deep(.van-calendar__header-subtitle){
  font-size: 15px;
  color: #FFFFFF;
  height: 28px;
  line-height: 28px;
  background: #282D46;
}
:deep(.van-calendar__bottom-info){
  bottom: -0;
  font-size: 6px;
  line-height: 1;
}
:deep(.van-calendar__top-info){
  top: 0;
  left:auto;
  font-size: 30px;
  color: #FF0000;
  height: 0;
  line-height: 0;
}
.rule{
  padding: 0 30px 10px;
  font-size: 10px;
  color: #B8B8B8;
}
:deep(.todayTime) {
  background: #FD5B03;
}
:deep(.lastTime){
  color: #414660;
}
.disabled{
  :deep(.van-calendar__confirm){
    pointer-events: none;
    background: #979797 !important;
    border-color:#979797 !important;
  }
}

:deep(.nextTime .van-calendar__bottom-info){
  color: #FD5B03;
}
</style>

<style>
.myDialog{
  background: #111425;
  .van-dialog__header,
  .van-dialog__content,
  .van-dialog__message{
    color: #E8e8e8;
  }
  .van-action-bar{
    background: transparent;
  }
  .van-dialog__confirm{
    background: linear-gradient(130.94deg, #FD9403 16.87%, #FD5B03 89.42%);
  }
}
</style>
