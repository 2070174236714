import { InternalStepInnerType, InternalStepInnerTypeVal } from '~/enums/InternalStepInnerType'
import { InternalStepType, InternalStepTypeVal } from '~/enums/InternalStepType'

export function useInternal_step_func() {
  const router = useRouter()
  function _stepToActivity(id: string) {
    router.push({ path: '/activity', query: { id } })
  }

  function _stepToInnerMainPage(index: number, options?: TypeParam) {}

  async function _stepToInnerPage(id: string) {
    let areaName: string = ''
    console.log('id', id)
    switch (InternalStepInnerTypeVal[Number(id) || 20]) {
      case InternalStepInnerType.home:
        areaName = '首页'
        _stepToInnerMainPage(0)
        break
      case InternalStepInnerType.homeFree:
        areaName = '首页-限免'
        // _stepToInnerMainPage(0)
        router.push('/home-free')
        break
      case InternalStepInnerType.homeRank:
        areaName = '首页-排行榜'
        router.push('/ranking-list')
        break
      case InternalStepInnerType.homeVIP:
        areaName = '首页-VIP区'
        router.push('/home-vip')
        break
      case InternalStepInnerType.homeProducer:
        areaName = '首页-片商列表'
        router.push('/merchant')
        break
    }
  }
  /**
   * 跳转目标[0:活动,1:外部地址,2:固定界面,3:影片详情界面,4:女优详情界面,5:厂商详情界面]
   * @param model
   * @param adPosition
   */
  function internalStepFunc(model: BannerModel, adPosition: string) {
    console.log('🚀 ~ internalStepFunc ~ model:', model, 3333, Object.values(InternalStepType)[model.linkTarget || 5])
    try {
      if (model.isLink === 1) {
        switch (InternalStepTypeVal[model.linkTarget || 5]) {
          case InternalStepType.activity:
            _stepToActivity(model.targetUrl || '')
            break
          case InternalStepType.innerPage:
            _stepToInnerPage(model.targetUrl || '')
            break
          default:
            break
        }
      }
    } catch (error) {
      console.log('🚀 ~ internalStepFunc ~ error:', error)
    }
  }

  return {
    internalStepFunc
  }
}
