<script setup lang="ts">
// import icon_h_paihangbang from '~/assets/image/home/main/icon_h_paihangbang.png'
// import icon_h_pianshang from '~/assets/image/home/main/icon_h_pianshang.png'
// import icon_h_group from '~/assets/image/home/main/icon_h_group.png'
// import icon_h_vip from '~/assets/image/home/main/icon_h_vip.png'
// import icon_h_xianmian from '~/assets/image/home/main/icon_h_xianmian.png'
import { Subject } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'
import { MainApi } from '~/net/apis/main_api'

const { internalStepFunc } = useInternal_step_func()
const AdcolumnStore = useAdcolumn()
const taskStatus = ref(false)
// const AdcolumnStatus = ref(AdcolumnStore.popupShow)
const { y } = useWindowScroll()
const quickAccess = useObservable(from(MainApi.queryBanner({ bannerType: '11' })).pipe(map(({ data }) => data)))

const sub = new Subject<HomeTab | null>()
const active = ref(0)
const tagsList = useObservable(
  from(MainApi.tabQuery()).pipe(
    map(({ data }) => data),
    tap((data) => sub.next(data[0]))
  )
)
const tagsItem = computed(() => (tagsList.value ? tagsList.value[active.value] : {}))
const titleList = useObservable(
  sub.pipe(
    switchMap((res) =>
      MainApi.videoCom({ pageNum: 1, pageSize: 10, tabId: String(res?.tabId) || String(tagsItem.value.tabId) || '0' })
    ),
    map(({ data }) => data.record || [])
  )
)
onUnmounted(() => sub.unsubscribe())
onUnmounted(() => AdcolumnStore.setAdColumn(false))
function showTask() {
  // taskStatus.value = true
  // AdcolumnStatus.value = true
}

const meta = {
  cache: true // 开启页面缓存
}
</script>

<template>
  <div>
    <TheHeader :class="[y > 5 && 'shadow-md shadow-slate-800']"
      @show-task='showTask'
      />
    <van-tabs
      v-model:active="active"
      @click-tab="sub.next(null)"
      title-inactive-color="#e8e8e8"
      mb-10
      mt-17.5
      min-h-screen
      bg-111425
    >
      <van-tab v-for="item in tagsList" :key="item.tabId" px-4 :title="`${item.tabName}`">
        <div v-if="active === 0">
          <div h-150px>
            <BannerPage :key="0" :banner-type="{ type: 1, code: 0 }" />
          </div>
          <!-- {{ quickAccess }} -->
          <div flex justify-between px-0 py-20px>
            <div v-for="(item, k) in quickAccess" @click="internalStepFunc(item, item.alias || '')" :key="k" flex>
              <div flex-1 flex-col text-center>
                <ImgWrapper class="mb-2 h-50px" :src="`${item.pictureUrl}`" />
                <span ct-E8E8E8 text-13px whitespace-nowrap>{{ item.alias }}</span>
              </div>
            </div>
          </div>

          <div class="h-80px mb-24px">
            <BannerPage :key="1" :banner-type="{ type: 1, code: 1 }" />
          </div>
        </div>
        <CommonCellView2 v-for="items in titleList" :key="items.groupId" :title-data="items" />
      </van-tab>
    </van-tabs>
    <Task v-model="taskStatus" />
    <Adcolumn v-model="AdcolumnStore.popupShow" />
    <TheFooter fixed bottom-0 h-50 w-full />
  </div>
</template>

<style></style>
