<script lang="ts" setup>
import { map } from 'rxjs/operators'
import { NoticeApi } from '~/net/apis/notice_api'

const props = withDefaults(
  defineProps<{
    modelValue: boolean
  }>(),
  {
    modelValue: false
  }
)
const emits = defineEmits<{
  'update:modelValue': [value: boolean]
  close: [value: boolean]
}>()
const close = () => emits('update:modelValue', false)

const data = useObservable(from(NoticeApi.announcement()).pipe(map(({ data }) => data)))

</script>

<template>
  <van-overlay :show="modelValue" style="z-index: 2000">
      <div h-screen flex items-center justify-center>
        <div
          pos-relative flex flex-col items-center justify-center px-15px class="announcement warrper"
        @click.stop>
          <BaseIcon name="close" size="2.5" pos-absolute right-2 top-2 p-2 @click="close" />
          <p class="text-18px ct-e8e8e8 mb-2 poptitle">微博公告拦</p>
          <div class="content" v-html="data?.popContent" />
          <!-- <div round h-11 w-88 text-center ct-ffffff class="bg-bt" @click="close">关闭</div> -->
        </div>
      </div>
  </van-overlay>
</template>

<style scoped>
:deep(.van-loading--vertical) {
  justify-content: center;
}
.warrper{
  min-width: 300px;
  min-height: 300px;
  margin: 0 15px;
  background: #111425;
}
:deep(.van-calendar__day){
  font-size: 12px;
}
:deep(.van-calendar){
  background: #111425;
}
:deep(.van-calendar__header-title){
  color: #E8e8e8;
  background: #111425;
}
:deep(.van-calendar__header-subtitle){
  font-size: 15px;
  color: #FFFFFF;
  height: 28px;
  line-height: 28px;
  background: #282D46;
}
:deep(.van-calendar__bottom-info){
  bottom: -0;
  font-size: 6px;
  line-height: 1;
}
:deep(.van-calendar__top-info){
  top: 0;
  left:auto;
  font-size: 30px;
  color: #FF0000;
  height: 0;
  line-height: 0;
}
.content{
  font-size: 10px;
  color: #B8B8B8;
  :deep(p){
    line-height: normal;
  }
}
.rule{
  padding: 0 30px 10px;
  font-size: 10px;
  color: #B8B8B8;
}
:deep(.todayTime) {
  background: #FD5B03;
}
:deep(.lastTime){
  color: #414660;
}
.disabled{
  :deep(.van-calendar__confirm){
    pointer-events: none;
    background: #979797 !important;
    border-color:#979797 !important;
  }
}

:deep(.nextTime .van-calendar__bottom-info){
  color: #FD5B03;
}
</style>

<style>
.announcement{
  p{
    color: #e8e8e8;
    font-size: 12px;
    line-height: normal;
  }
  .poptitle{
    font-size: 18px;
  }
}
</style>
