import http from '~/utils/http'


export class NoticeApi {
  // @POST('/jav/notice/announcement')
  // Future<Popup> announcement();
  static announcement(opt?: HttpOptions) {
    return http.post<Popup>({ ...opt, url: '/jav/notice/announcement' })
  }

  // @POST('/jav/notice/special')
  // Future<Popup> special();
  static special(opt?: HttpOptions) {
    return http.post<Popup>({ ...opt, url: '/jav/notice/special' })
  }
}
