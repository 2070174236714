<script setup lang="ts">
import type { MediaType } from '~/types'

withDefaults(
  defineProps<{
    type?: MediaType
    title?: string
    titleData: VideoGroup
  }>(),
  {}
)
const emits = defineEmits<{
  headerClick: [value?: unknown]
}>()
const router = useRouter()

function headerClick(val?: MediaType) {
  console.log(val)
  if (val) {
    router.push(`/channel/${val}`)
  } else {
    emits('headerClick')
  }
}
</script>

<template>
  <div>
    <div py3 flex="~ justify-between">
      <p>
        <span mr-2 border-rd-20 px4 py-1 text-15px ct-111425 font-600 gradient-fec97e-ffb44c-145>
          {{ titleData.groupTitle }}
        </span>
        <span text-12px ct-6A708E>{{ titleData.groupSubTitle }}</span>
      </p>
      <div flex="~ items-center" cursor-pointer transition @click="headerClick(type)">
        <div i-ep:arrow-right-bold ma ct-ffffff />
      </div>
    </div>
    <div grid="~ cols-2 gap2">
      <div v-for="item in titleData.videoList" :key="item.videoId">
        <slot>
          <HorizontalCard :item="item" />
        </slot>
      </div>
    </div>
  </div>
</template>
