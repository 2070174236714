export const useAdcolumn = defineStore('Adcolumn', {
  state: () => ({
    popupShow: true
  }),
  getters: {
    AdcolumnStatus: (state) => {
      return state.popupShow
    }
  },

  actions: {
    setAdColumn(bol: boolean) {
      this.popupShow = bol
    }
  }
})
